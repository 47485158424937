//router
import LayoutsRoute from './router/layouts-route';
import { Helmet } from 'react-helmet';

//scss files
import './assets/css/bootstrap.min.css'
import './assets/css/typography.css'
import './assets/css/style.css';
import './assets/css/responsive.css'


// import  './assets/css/custom.css';

function App() {
  return (
    <div className="App">
        <Helmet>
            <meta charSet="utf-8" />
            <title>Artiflix Movies</title>
            <meta name="description" content="Rediscover timeless classics digitally revived on Artiflix.  Free to watch!" />
            <link rel="canonical" href="https://artiflix.com" />
        </Helmet>
      <LayoutsRoute />
    </div>
  );
}

export default App;
