// customHooks.js
import {useEffect, useState} from 'react';
import useSWR, {preload} from 'swr';
import axios from 'axios';
import {cms_movie, cms_token_dev} from './api-endpoints';

const fetcher = (url, token=cms_token_dev) =>
  axios
  .get(url, { headers: { Authorization: token } })
  .then((res) => res.data)
  .catch((error) => {
    console.error("Error fetching data:", error);
    return error;
  });
export const movieSearchResultData = ({sortBy, filter, page_size}) => {
  const [movieData, setMovieData] = useState([]);
  const [loading, setLoading] = useState(true);

  const filterQuery = Object.entries(filter)
      .map(([key, value]) => {
        if (key === 'genre' && Array.isArray(value) && value.length > 0) {
          return value.map(genre => `filters[genres][name][$in]=${genre}`).join('&');
        } else if (key === 'from_date' && value !== null) {
          return `filters[identification][release_date][$gte]=${value}`;
        } else if (key === 'to_date' && value !== null) {
          return `filters[identification][release_date][$lte]=${value}`;
        } else if (key === 'keywords' && value !== null) {
          return `filters[$or][0][identification][title][$containsi]=${value}&\
filters[$or][1][informational][long_description][$containsi]=${value}&\
filters[$or][2][credits][name][$in]=${value}&\
filters[$or][3][genres][name][$in]=${value}`
        }
      }).filter(Boolean).join('&');


  let whichSort = "";
  if (sortBy) {
    if (sortBy === 'title_a_to_z') {
      whichSort = `sort[identification][title]=asc`;
    }else if (sortBy === 'title_z_to_a') {
      whichSort = `sort[identification][title]=desc`;
    }else if (sortBy ==='release_date_ascending') {
      whichSort = `sort[identification][release_date]=asc`;
    }else if (sortBy === 'release_date_descending') {
      whichSort = `sort[identification][release_date]=desc`;
  }}

  const queryParam = {
    populate: `populate=*`,
    page_size: page_size ? `pagination[pageSize]=${page_size}` : `pagination[pageSize]=400`,
    sortBy: whichSort,
    filter: filterQuery || ''
  }

  const queryString = Object.values(queryParam)
      .filter(val => val !== '')
      .join('&');

  let request_url = [`${cms_movie}?${queryString}`];

  preload(request_url, fetcher)
  const { data, error } = useSWR(request_url, fetcher);

  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  useEffect(() => {
    if (data && Array.isArray(data.data)) {

      const ranCode = getRandomInt(data.length);
      const processedData = data.data.map((item, index) => {
        try {
          return {
            element_id: `${item.attributes['identification']['title'].replace(/\s+/g, '')}G${ranCode}-${index}`,
            movie_identifier: item.attributes['identification']['identifier'],
            title: item.attributes['identification']['title'],
            media_type: item.attributes['identification']['media_type'],
            original_language: item.attributes['identification']['original_language'],
            informational_rating: item.attributes['informational_rating'][0]['value'],
            content_thumbnail: item.attributes['content_thumbnail'],
            genres: item.attributes['genres']['data'].map(genre => genre.attributes['name']).join(" • ")
          };
        } catch (error) {
          console.error("Error processing item:", item, error);
          return null;
        }
      }).filter((item) => item!== null);

      setMovieData(processedData)
      setLoading(false);
    }
  }, [data]);
  return { movieData, error, loading };
};
