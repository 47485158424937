import React, {useEffect, useState} from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {getRtlMode, rtlModeAction} from '../../../../store/mode/rtlmode'

//popup
import PrivacyPolicyPopup from '../../../../views/backend/pages/extrapages/privacyPolicyPopup'
import TermsOfUsePopup from '../../../../views/backend/pages/extrapages/termsOfUsePopup'
import ContactFormPopup from '../../../../views/backend/pages/extrapages/contactFormPopup'


const mapStateToProps = (state) => {
    return {
        rtlMode: getRtlMode(state)
    };


}
const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
            rtlModeAction
        },
        dispatch
    )
})

const FooterStyle = (props) =>{
  const [showPrivacyPolicyPopup, setShowPrivacyPolicyPopup] = useState(false);
  const handlePrivacyPolicyPopupClose = () => setShowPrivacyPolicyPopup(false);
  const handlePrivacyPolicyPopupShow = () => setShowPrivacyPolicyPopup(true);

  const [showTermsOfUsePopup, setTermsOfUsePopup] = useState(false);
  const handleTermsOfUsePopupClose = () => setTermsOfUsePopup(false);
  const handleTermsOfUsePopupShow = () => setTermsOfUsePopup(true);

  const [showContactPopup, setContactPopup] = useState(false);
  const handleContactPopupClose = () => setContactPopup(false);
  const handleContactPopupShow = () => setContactPopup(true);

  const [show, setShow] = useState(false);

  return(
      <>
        <footer id="contact" className="footer-two">
          <Container fluid>
            <Row>
              <Col className="footer-menu-container" lg="6" sm="12">
                <ul className="list-inline">
                  <li className="list-inline-item"><Link to="/about">About</Link></li>
                  <li className="list-inline-item"> <Link onClick={handlePrivacyPolicyPopupShow}>Privacy Policy</Link></li>
                  <li className="list-inline-item"><Link onClick={handleTermsOfUsePopupShow}>Terms of Use</Link></li>
                  <li className="list-inline-item"><Link onClick={handleContactPopupShow}>Contact</Link></li>
                </ul>

              </Col>
              <Col className="footer-rights-container text-center" lg="6" sm="12">
                  © {new Date().getFullYear()} Artiflix. All Rights Reserved.
              </Col>
            </Row>
          </Container>
        </footer>

        <PrivacyPolicyPopup show={showPrivacyPolicyPopup} handleClose={handlePrivacyPolicyPopupClose} />
        <TermsOfUsePopup show={showTermsOfUsePopup} handleClose={handleTermsOfUsePopupClose} />
        <ContactFormPopup show={showContactPopup} handleClose={handleContactPopupClose} />
      </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(FooterStyle)