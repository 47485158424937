import React from 'react';
import {Link, useParams} from 'react-router-dom'
// Partials
import HeaderStyle1 from '../../components/partials/backend/headerstyle/headerstyle1';
import FooterStyle from '../../components/partials/backend/footerstyle/footerstyle'


// Router Component
import Layout1Route from '../../router/layout1-route'

const Layout1 = () => {

    return (
        <>
            <HeaderStyle1/>
            <div className="">
                <div className="content-page" id="content-page">
                    <Layout1Route/>
                </div>
            </div>
            <FooterStyle/>
        </>
    )
}

export default Layout1;