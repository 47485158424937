import {useMovieDetailData} from '../../../components/data/use-movie-data'; // adjust the import as needed
import {Col, Container, Row} from 'react-bootstrap';
import {useNavigate, useParams} from 'react-router-dom';
import MovieRatings from '../movie/movie-ratings';
import tmdbLogo from '../../../assets/images/about-us/tmdb-logo.svg';
import React, {useEffect, useState} from 'react';
import {ChangeMeta} from '../seo/change-meta'
import VideoJS from '../../../components/partials/backend/player/Player.jsx'
import videojs from "!video.js";

const MovieDetailsView = () => {
    const playerRef = React.useRef(null);
    const {movieIdentifier} = useParams();
    const {movieDetail: movieData, loading, error} = useMovieDetailData(movieIdentifier);
    const navigate = useNavigate();
    const handleClose = (e) => {
        navigate(-1);
    };
    const DEFAULT_SEO_DATA = {};
    const [currentSeoData, setCurrentSeoData] = useState(DEFAULT_SEO_DATA);
    const [movieUrl,setMovieUrl] = useState(null);

    //if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    //const watchPath = `/movie/${movieData.movie_identifier}`;

    useEffect(() => {
        const movieId = movieData.movie_identifier || ""
        const genre = movieData.genres || ""
        const genresArray = genre.split(" • ")
        const movieTitle = movieData.title || ""
        const yearReleased = movieData.year_released || ""
        const longDescription = movieData.longDescription || ""
        const movieURL = (movieId === "") ? "https://artiflix.com" : "https://artiflix.com/video/" + movieId
        const theKeywords = `${genre}, ${movieTitle}, ${yearReleased}, Artiflix, Forgotten Cinematic Treasures, Classic Movies Streaming, Free`
        const theTitle = (movieTitle === "") ? 'Artiflix Movies' : `Artiflix - ${movieTitle}`
        const theDescription = `${longDescription} - Rediscover timeless classics digitally revived on Artiflix. Movie videos free to watch!`
        const movieImageUrl = movieData.content_thumbnail_1 || ""
        const movieSourceURL = `https://vz-9273a21d-64a.b-cdn.net/${movieId}/playlist.m3u8/`
        setMovieUrl(movieSourceURL)
        setCurrentSeoData({
            title: theTitle,
            description: theDescription,
            url: movieURL,
            shareImage: movieData.content_thumbnail_1,
            keywords: theKeywords,
            genre: genresArray,
            yearReleased: yearReleased,
            movieImage: movieImageUrl,
            duration: movieData.duration8601,
            sourceURL: movieSourceURL
        });
        gtag('event', 'view_movie_details', {
            'event_category': 'movie',
            'event_label': movieTitle,
            'movie_id': movieId,
            'genre': genre
        });
    }, [movieData]);

useEffect(() => {
    if (!isUUID(movieIdentifier)) {

        navigate(-1);
    }

}   , [movieIdentifier]);

    function isUUID(uuid) {
        let regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/i;
        return regex.test(uuid);
    }


    const MovieScreen = () => {
        const videoJsOptions = {
            autoplay: false,
            controls: true,
            responsive: true,
            fluid: true,
            skipButtons: true,

            poster: movieData.content_thumbnail_1,
            sources: [{
                src: `https://vz-9273a21d-64a.b-cdn.net/${movieIdentifier}/playlist.m3u8`,
                type: 'application/x-mpegURL'
            }],
            html5: {
                hls: {
                    enableLowInitialPlaylist: true,
                    smoothQualityChange: true,
                    overrideNative: true,
                },
            },
            controlBar: {
                skipButtons: {
                    forward: 10,
                    backward: 10
                },
                remainingTimeDisplay: {
                    displayNegative: false,
                }
            }
        };

        const handlePlayerReady = (player) => {
            playerRef.current = player;

            // You can handle player events here, for example:
            player.on('waiting', () => {
                videojs.log('player is waiting');
            });

            player.on('dispose', () => {
                videojs.log('player will dispose');
            });
        };

        return (
            <>
                {(movieUrl)?<VideoJS options={videoJsOptions} onReady={handlePlayerReady} />:<div>Loading...</div>}
            </>
            )

    }
    return (
        <>
            <ChangeMeta seoData={currentSeoData}/>
            <div className="movie-details-page">
                <div className="page-dialog">
                    <div className="page-content">
                        <div className="page-header">
                            <button onClick={handleClose} className="close movie-close-button">X</button>
                        </div>

                        <Container fluid className="page-body">
                            <MovieScreen />
                            <Row>
                                <Col className="title-container pt-2" lg="12" sm="12">
                                    <h1 className="title" aria-label={`video ${movieData.title}`}>{movieData.title}</h1>
                                </Col>
                                <Col className="movie-description py-2" lg="12" sm="12">
                                    {movieData.longDescription}
                                </Col>
                                <Col className="mb-1" lg="12" sm="12">
                                    <div className="other-details">
                                        <div>{movieData.duration}</div>
                                        <div>•</div>
                                        <div>{movieData.year_released}</div>
                                        <div>•</div>
                                        <div className="genres">
                                            {movieData.genres}
                                        </div>
                                        <div>•</div>
                                        <div className="rated">{movieData.informational_rating}</div>
                                    </div>
                                    <div className="other-details">
                                        <div className="position-relative">
                                            {/*<div className="rating-container">*/}
                                            {/*    <MovieRatings movie_title={movieData.title}*/}
                                            {/*                  movie_year={movieData.year_released}/>*/}
                                            {/*</div>*/}
                                        </div>
                                        {/*<div className="hover-text"*/}
                                        {/*     data-hover-text="">*/}
                                        {/*    User Score <img src={tmdbLogo} alt="TMDB Logo" width="40"*/}
                                        {/*                    height="40"></img> &nbsp;*/}
                                        {/*</div>*/}

                                    </div>
                                </Col>


                            </Row>
                        </Container>
                    </div>
                </div>
            </div>

        </>
    );
};

export default MovieDetailsView;