import {Helmet} from 'react-helmet';

const ChangeMeta = ({seoData}) => {

    const structuredData = {
        "@context": "http://schema.org",
        "@type": "Movie",
        "name": seoData.title,
        "url": seoData.url,
        "datePublished": seoData.yearReleased,
        "genre": seoData.genre,
        "description": seoData.description,
        "image": seoData.movieImage,
        "video": {
            "@type": "VideoObject",
            "name": seoData.title,
            "description": seoData.description,
            "thumbnailUrl": seoData.movieImage,
            "contentUrl": seoData.sourceURL,
            "uploadDate": "2023-07-01T01:23:45-08:00",
            "duration": seoData.duration,
            "publisher": {
                "@type": "Organization",
                "name": "Artiflix",
                "url": "https://artiflix.com",
                "logo": {
                    "@type": "ImageObject",
                    "url": "https://artiflix.com/logo192.png",
                    "width": 192,
                    "height": 192
                }
        },
        }
    };

    return (
        <Helmet>
            <title>{seoData.title}</title>
            <meta name="description" content={seoData.description} key="description" />
            <meta name="keywords" content={seoData.keywords} />

            <meta property="og:url" content={seoData.url} key="og:url" />
            <meta property="og:title" content={seoData.title} key="og:title" />
            <meta property="og:type" content="video.movie" key="og:type" />
            <meta
                property="og:description"
                content={seoData.description}
                key="og:description"
            />
            <meta property="og:image" content={seoData.shareImage} key="og:image" />
            <link rel="canonical" href={seoData.url} />

            <script type="application/ld+json">
                {JSON.stringify(structuredData)}
            </script>

        </Helmet>
    );
};

const ChangeMetaBasic = ({seoData}) => {
    return (
        <Helmet>
            <title>{seoData.title}</title>
            <meta name="description" content={seoData.description} key="description" />
            <link rel="canonical" href={seoData.url} />
        </Helmet>
    );
};

export {ChangeMeta, ChangeMetaBasic};