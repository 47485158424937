import {Route, Routes, useLocation, useNavigate, useParams} from 'react-router-dom'
import {useEffect, useState} from 'react';
import {CSSTransition, TransitionGroup} from "react-transition-group";

//Extrapages
import About from '../views/backend/pages/about'

//Category
//Movie
import MovieLibrary from '../views/backend/movie/library';

// Redirection component
/*const RedirectComponent = () => {
    const {movieIdentifier} = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        navigate(`/video/${movieIdentifier}`, { replace: true });
    }, [navigate, movieIdentifier]);

    return null;
};*/

//Show
// Home
import Homepage from '../views/backend/home/home'
import Notfound from '../views/backend/pages/auth/NotFound'
import MovieDetailsView from "../views/backend/home/MovieDetailsView";

const Layout1Route = () => {

    let location = useLocation();

    return (
        <TransitionGroup>
            <CSSTransition
            // key={location.key}
            classNames="fade"
            timeout={300}
            >
                <Routes  location={location}>

                    {/* Extrapages */}
                    <Route path="/about"                 element={<About/>}/>
                    
                    {/* Movie */}
                    <Route exact path="/video/:movieIdentifier" element={<MovieDetailsView/>}/>

                    {/*<Route path="/movie-category"           element={<MovieList/>}/>*/}
                    <Route path="/library" exact          element={<MovieLibrary/>}/>

                    {/* homepage */}
                    <Route path="/" exact                   element={<Homepage/>}/>

                    {/* catch all for incorrect pages */}
                    <Route path="*" element={<Notfound/>} />



                </Routes>
            </CSSTransition>
        </TransitionGroup>
    )
}

export default Layout1Route