import React, {useState} from 'react';
import axios from 'axios';
import {Button, Form, Modal} from 'react-bootstrap'

const ContactFormPopup = ({ show,handleClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [thankYouMessage, setThankYouMessage] = useState(false);

  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    subject: '',
    message: '',
    consent: false
  });

  const [formErrors, setFormErrors] = useState({
    firstname: '',
    lastname: '',
    email: '',
    subject: '',
    message: '',
    consent: false
  });

  const handleChange = (e) => {
    setThankYouMessage(''); //Clear Thank You Message

    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    //setFormData({ ...formData, [e.target.name]: e.target.value });
    setFormData({ ...formData, [e.target.name]: value });
    setFormErrors('');
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!formData.firstname) {
      isValid = false;
      errors.firstname = 'Please enter your First Name';
    }

    if (!formData.lastname) {
      isValid = false;
      errors.lastname = 'Please enter your Last Name';
    }

    if (!formData.email) {
      isValid = false;
      errors.email = 'Please enter your Email';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      isValid = false;
      errors.email = 'Please enter a valid Email Address';
    }

    if (!formData.subject) {
      isValid = false;
      errors.subject = 'Please enter a Subject';
    }

    if (!formData.message) {
      isValid = false;
      errors.message = 'Please enter a Message';
    }

    setFormErrors(errors);
    return isValid;
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    const portal_id = '23964702'
    const form_guid = '371162e9-5735-4d9e-bcad-d133d9b678c0'
    const hubspotFormEndpoint = `https://api.hsforms.com/submissions/v3/integration/submit/${portal_id}/${form_guid}`;

    if (validateForm()) {
      setIsLoading(true); // Set loading state to true

      const headers = {
        'Content-Type': 'application/json'
      };
      axios.post(
        hubspotFormEndpoint,
        {
              submittedAt: Date.now(),
              fields: [
                {
                  name: 'firstname',
                  value: formData.firstname
                },
                {
                  name: 'lastname',
                  value: formData.lastname
                },
                {
                  name: 'subject',
                  value: formData.subject
                },
                {
                  name: 'email',
                  value: formData.email
                },
                {
                  name: 'message',
                  value: formData.message
                }
              ]

            }, { headers }
      )
      .then(response => {
        // Reset the form data after successful submission
        setFormData({
          firstname: '',
          lastname: '',
          email: '',
          subject: '',
          message: '',
          consent: false
        });
        console.log('Form submitted successfully!', response.data);
        setThankYouMessage(response.data.inlineMessage);
        // Handle success response or perform any additional actions
      })
      .catch(error => {
        console.error('Error submitting form:', error);
        // Handle error response or display an error message
      }).finally(() => {
        setIsLoading(false); // Set loading state back to false
      });
    }
  };
  return (
    <Modal size="lg" show={show} onHide={handleClose} className="contact-us-modal">
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Contact Us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="firstname">
            <Form.Label>First Name</Form.Label>
            <Form.Control type="text" name="firstname" placeholder="Enter First Name" value={formData.firstname} onChange={handleChange} />
            {formErrors.firstname && <span className="errorMessage">{formErrors.firstname}</span>}
          </Form.Group>
          <Form.Group controlId="lastname">
            <Form.Label>Last Name</Form.Label>
            <Form.Control type="text" name="lastname" placeholder="Enter Last Name" value={formData.lastname} onChange={handleChange} />
            {formErrors.lastname && <span className="errorMessage" >{formErrors.lastname}</span>}
          </Form.Group>
          <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" name="email" placeholder="Enter Email" value={formData.email} onChange={handleChange} />
            {formErrors.email && <span className="errorMessage">{formErrors.email}</span>}
          </Form.Group>
          <Form.Group controlId="subject">
            <Form.Label>Subject</Form.Label>
            <Form.Control type="text" name="subject" placeholder="Enter Subject of Message" value={formData.subject} onChange={handleChange} />
            {formErrors.email && <span className="errorMessage">{formErrors.email}</span>}
          </Form.Group>
          <Form.Group controlId="message">
            <Form.Label>Message</Form.Label>
            <Form.Control as="textarea" name="message" rows={3} value={formData.message} onChange={handleChange}  />
            {formErrors.message && <span className="errorMessage">{formErrors.message}</span>}
          </Form.Group>
          <div className="thank-you-message"> {thankYouMessage ? thankYouMessage : ''}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" type="submit" disabled={isLoading}>
            {isLoading ? 'Submitting...' : 'Submit'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default ContactFormPopup;