import React, {useState} from 'react'
import MovieRatings from "./movie-ratings";


const MovieItem = ({ item }) => {
  const [isHovered, setIsHovered] = useState(false);

  let thumbnail_url = "";
  if(item.content_thumbnail) {
    item.content_thumbnail.forEach((item)=> {
      if(item.name.toLowerCase() === 'keyart9-16' && item.source_url){
        thumbnail_url = item.source_url + '?width=320';
      }
    })
  }

  const handleHover = () => {
    setIsHovered(!isHovered);
  };



  return (
    <>
      <div
        className="block-images position-relative"
        onMouseEnter={handleHover}
        onMouseLeave={handleHover}

      >
        <div className="img-box block-images">
            <img src={thumbnail_url} className="img-fluid" alt=""/>
        </div>
        <div  className="position-relative">
          {/*<div className="rating-container rating-absolute"><MovieRatings movie_title={item.title}  movie_year={item.year_released}/></div>*/}
        </div>
        <h2 className="movie-title">{item.title}</h2>

        <div onMouseLeave={handleHover} className={`movie-hover-content ${isHovered ? 'show' : ''}`}>
          <div className="title">{item.title}</div>
          {item.genres && (
            <div className="genres">
              { item.genres }
            </div>
          )}
          <div className="other_details">
            {item.informational_rating && ( <div> {item.informational_rating}</div>   )}
            {item.media_type && (<div> {item.media_type}</div>  )}
            {item.original_language && (<div> {item.original_language}</div>  )}
          </div>
        </div>
      </div>
    </>
  );
};
export default MovieItem