import React, {useEffect, useState} from 'react'
import {Button, Col, Container, Form, FormControl, InputGroup, Row} from 'react-bootstrap'

import MovieSlider from "../movie/movie-slider";
import {ChangeMetaBasic} from '../seo/change-meta'

const Homepage = () => {
    const [show, setShow] = useState(false);
    const [movieIdentifier, setMovieIdentifier] = useState('');
    const [selectedGenres, setSelectedGenres] = useState([]);

    const handleClose = () => setShow(false);
/*    const handleCloseModified = () => {
        setCurrentSeoData(DEFAULT_SEO_DATA);  // Reset metadata
        handleClose();  // Call the original handleClose function
    }*/

    const handleShow = (movieIdentifier) => {
        setMovieIdentifier(movieIdentifier);
        setShow(true);
    }

    const DEFAULT_SEO_DATA = {
        title: 'Artiflix Movies',
        description: 'Rediscover timeless classics digitally revived on Artiflix',
        url: 'https://artiflix.com',
        shareImage: 'https://artiflix.com/logo192.png',
        keywords: 'Artiflix, Forgotten Cinematic Treasures, Classic Movies Streaming'
    };

    const [currentSeoData, setCurrentSeoData] = useState(DEFAULT_SEO_DATA);

    const allGenres = [
        'Action',
        'Adventure',
        'Children',
        'Comedy',
        'Crime',
        'Drama',
        'Horror',
        'Mystery',
        'Romance',
        'Science Fiction',
        'Thriller',
        'Western']

    // Function to select 5 random genres
    const selectRandomGenres = (genres, count) => {
        let shuffled = genres.sort(() => 0.5 - Math.random());
        return shuffled.slice(0, count);
    };

    // On component mount, select random genres
    useEffect(() => {
        setSelectedGenres(selectRandomGenres(allGenres, 5));
    }, []);

    // Function to handle scroll event
    const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
        if (scrollTop + clientHeight === scrollHeight) {
            // Add logic to trigger the display of additional slider components
            const newGenres = selectRandomGenres(allGenres, 3); // Generate new random genres
            setSelectedGenres((prevGenres) => [...prevGenres, ...newGenres]); // Append new genres to the existing list
        }
    };

    useEffect(() => {
        // Attach scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        setCurrentSeoData({
            title: 'Artiflix Movies',
            description: 'Rediscover timeless classics digitally revived on Artiflix',
            url: 'https://artiflix.com',
        });
    }, []);


    return (
        <>
            <ChangeMetaBasic seoData={currentSeoData} />
            <div className="homepage-hero-container">
                <Container fluid>
                    <Row>
                        <Col className="title" lg="12" sm="12">
                            <div>
                                <h1 className=''>Welcome to Artiflix</h1>
                            </div>
                        </Col>
                        <Col className="homepage-search-container mb-3 mt-lg-2" lg="12" sm="12">
                            <Form action="/library" method="GET">
                                <InputGroup className="homepage-search">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="basic-addon1">
                                            <i className="fa fa-search"></i>
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                        placeholder="Find Your Favorite Titles or Actors"
                                        name="search"
                                        aria-label="search"
                                        aria-describedby="search"
                                    />
                                    <InputGroup.Append>
                                        <Button type="submit" className="search-btn">Search</Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="main-content">
                {selectedGenres.map((genre, index) => (
                    <div key={genre+index} className="movie-slider-container mb-4">
                    <MovieSlider key={genre} id={genre} playlist={genre} title={genre}
                                 isGenre={true} navPrevId={`prev`+ genre + index} navNextId={`next`+ genre + index} movieModalShow={handleShow}/>
                    </div>
                ))}
            </div>
        </>
    )
}

export default Homepage