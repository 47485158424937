// customHooks.js
import {useEffect, useState} from 'react';
import useSWR, {preload} from 'swr';
import axios from 'axios';
import {cms_movie, cms_token_dev} from './api-endpoints';

const fetcher = (url, token=cms_token_dev) =>
    axios
        .get(url, { headers: { Authorization: token } })
        .then((res) => res.data)
        .catch((error) => {
            console.error("Error fetching data (@useMovieData):", error);
            return error;
        });
export const useMovieData = (type, value) => {
    const [movieData, setMovieData] = useState([]);
    const [loading, setLoading] = useState(true);
    const queryParam = type === 'genre' ?  'filters[genres][name][$eq]':'filters[playlists][name][$eq]';
    preload(value ? [`${cms_movie}?populate=*&${queryParam}=${value}`] : null, fetcher)
    const { data, error } = useSWR(
        value ? [`${cms_movie}?populate=*&${queryParam}=${value}`] : null,
        fetcher
    );

    function getRandomInt(max) {
        return Math.floor(Math.random() * max);
    }

    useEffect(() => {
        if (data && Array.isArray(data.data)) {

            const ranCode = getRandomInt(data.length);
            const processedData = data.data.map((item, index) => {
                try {
                    return {
                        element_id: `${item.attributes['identification']['title'].replace(/\s+/g, '')}G${ranCode}-${index}`,
                        movie_identifier: item.attributes['identification']['identifier'],
                        title: item.attributes['identification']['title'],
                        media_type: item.attributes['identification']['media_type'],
                        original_language: item.attributes['identification']['original_language'],
                        informational_rating: item.attributes['informational_rating'][0]['value'],
                        content_thumbnail: item.attributes['content_thumbnail'],
                        genres: item.attributes['genres']['data'].map(genre => genre.attributes['name']).join(" • "),
                        year_released: item.attributes['identification']['release_date'].split('-')[0]
                    };
                } catch (error) {
                    console.error("Error processing item:", item, error);
                    return null;
                }
            }).filter((item) => item!== null);

            setMovieData(processedData)
            setLoading(false);
        }
    }, [data]);
    return { movieData, error, loading };
};

export const useMovieDetailData = (movieIdentifier) => {
    const [movieDetail, setMovieDetail] = useState({});
    const [loading, setLoading] = useState(true);

    const { data, error } = useSWR(
        movieIdentifier ? `${cms_movie}?populate=*&filters[identification][identifier][$eq]=${movieIdentifier}` : null,
        fetcher
    );

    useEffect(() => {
        if (data && data.data && data.data[0]) {
            const item = data.data[0];
            try {
                const duration_hours = Math.trunc(item.attributes['informational']['duration'] / 3600)
                const duration_min = Math.trunc((item.attributes['informational']['duration'] - duration_hours * 3600) / 60)
                const duration_string = `${duration_hours}h ${duration_min}m`
                const duration8601 = `PT${duration_hours}H${duration_min}M0S`
                let thumbnail_1 = '';
                let thumbnail_2 = '';
                if(item.attributes['content_thumbnail']) {item.attributes['content_thumbnail'].forEach((th_item)=> {
                    if(th_item.name.toLowerCase() === 'keyart9-16') {thumbnail_1 = th_item.source_url + '?width=320';}
                    if(th_item.name.toLowerCase() === 'keyart16-6') {thumbnail_2 = th_item.source_url + '?width=320';}
                })}

                const processedData = {
                    movie_identifier: item.attributes['identification']['identifier'],
                    title: item.attributes['identification']['title'],
                    media_type: item.attributes['identification']['media_type'],
                    original_language: item.attributes['identification']['original_language'],
                    informational_rating: item.attributes['informational_rating'][0]['value'],
                    content_thumbnail_1: thumbnail_1,
                    content_thumbnail_2: thumbnail_2,
                    genres: item.attributes['genres']['data'].map(genre => genre.attributes['name']).join(" • "),
                    year_released: item.attributes['identification']['release_date'].split('-')[0],
                    longDescription:  item.attributes['informational']['long_description'],
                    duration: duration_string,
                    duration8601: duration8601
                };

                setMovieDetail(processedData);
                setLoading(false);
            } catch (error) {
                console.error("Error processing item:", item, error);
            }
        }
    }, [data]);

    return { movieDetail, error, loading };
};