import {Route, Routes} from 'react-router-dom'
import Layout1 from '../layouts/backend/layout1'

const LayoutsRoute = props => {

    return (
        <Routes>
            <Route path="/*" element={<Layout1/>} />
        </Routes>
    )
}

export default LayoutsRoute