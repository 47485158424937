import React from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import {movieSearchResultData} from '../../../components/data/movie-search-result'
import MovieItem from "../movie/item";
import { Link } from 'react-router-dom';

const SearchResult = ({keywords, sortBy, fromDate, toDate, genre}) => {

  let fromDateISO = fromDate.toISOString();
  let toDateISO = toDate.toISOString();

  let filter = {
    genre,
    from_date: fromDateISO,
    to_date: toDateISO,
    keywords
  };

  const pageSize = 800;
  const {movieData, error, loading} = movieSearchResultData({sortBy, filter, page_size: pageSize});


  const SearchResultItems = movieData.map((item) => {
    return (
      <Col
          key={item.element_id}
        className="search-result-item"
        sm="6"
        lg="2"
      >
        <Link to={`/video/${item.movie_identifier}`}>
        <MovieItem key={item.element_id} item={item} />
        </Link>
      </Col>

    )
  })

  return (
    <>
      <div className="search-result-container" >
        <Container fluid>
          <Row>
            {SearchResultItems}
            <Col sm="12">
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default SearchResult