import React, {useState} from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import {Link} from 'react-router-dom'

//img

import ContactFormPopup from '../pages/extrapages/contactFormPopup';
import {ChangeMetaBasic} from "../seo/change-meta";

const About = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const seoData = {
        title: 'About Artiflix',
        description: 'Welcome to Artiflix, your gateway to a world of forgotten cinematic treasures!',
        url: 'https://artiflix.com/about',
    }

    return (
        <>
            <ChangeMetaBasic seoData={seoData}/>
            <main id="about-us-main">
                <div fluid="true" className="about-hero-container">
                    <Container>
                        <Row>
                            <Col className="about-us-title mb-sm-4" lg="12" sm="12">
                                <h1>ARTIFLIX</h1>
                            </Col>
                            <Col className="about-us-content" lg="12" sm="12">
                                <h3 className="text-center">
                                    Rediscovering Classic Entertainment
                                </h3>
                                <p className="mt-4">
                                    Welcome to Artiflix, your gateway to a world of forgotten cinematic treasures! Our
                                    mission is to bring you thousands of classic movies that were once thought to be
                                    lost to the ravages of time and film degradation. Through extensive efforts, our
                                    master collectors scoured the depths of film history to assemble and convert these
                                    rare gems into a stunning digital library. Now, you have the opportunity to make
                                    them a part of your personal collection.
                                </p>
                                <p className="mt-4">
                                    Our library boasts an unparalleled collection of over 1,000 titles, ranging from
                                    mainstream classics produced by major studios to under-appreciated and
                                    under-financed cult classics. The latter emerged from the independent film studios
                                    affectionately known as "Poverty Row," where these imaginative and creative
                                    filmmakers often outshone their more prominent counterparts.
                                </p>
                                <p className="mt-4">
                                    In the past, accessing these films was a challenge. Many of our titles were only
                                    available on VHS or as part of expensive collections. Artiflix has changed that by
                                    offering forgotten classics and special interest features as individual titles, all
                                    at popular prices that the "big" distributors simply can't match. We've done the
                                    digging and detective work for you, so now you can sit back, butter your popcorn,
                                    and enjoy the magic of these timeless masterpieces.
                                </p>
                                <p className="mt-4">
                                    Artiflix is more than just a streaming service; it is a community of people who
                                    share a passion for classic entertainment. Our team, consisting of renowned
                                    broadcast, major studio, radio, TV, and technology entrepreneurs, has come together
                                    to create a platform that celebrates the golden age of cinema.
                                </p>
                                <p className="mt-4">
                                    With Artiflix, you gain access to a vast collection of classic entertainment,
                                    including movies, TV shows, and soundies, ranging from arthouse films to B movies
                                    from Poverty Row. What sets us apart is our commitment to converting these analog
                                    treasures into digital quality, making them accessible to all at prices that won't
                                    break the bank. Many of our titles are not available on DVD, are only part of
                                    expensive collections, or sold individually at premium prices elsewhere.
                                </p>
                                <p className="mt-4">
                                    We've made sure that Artiflix is accessible to everyone. You can enjoy our service
                                    on a variety of devices, including Roku, Amazon, and through our website. Whether
                                    you're at home or on the go, the magic of classic entertainment is just a click
                                    away.
                                    At Artiflix, we believe that everyone should have the opportunity to indulge in the
                                    magic of classic films and TV shows, regardless of their budget. That's why we offer
                                    our service for free!
                                </p>
                                <p className="mt-4">
                                    If you're looking for a way to immerse yourself in the enchantment of classic
                                    entertainment, Artiflix is the perfect solution. With our wide variety of titles at
                                    no cost, we aim to provide you with a great experience that will transport you to a
                                    bygone era. Click here to start browsing our extensive library and rediscover the
                                    magic of classic entertainment!
                                </p>
                                <p className="mt-2">
                                    <Link onClick={handleShow} className="contact-button"><span>Contact Artiflix</span></Link>
                                </p>
                            </Col>
                        </Row>
                    </Container>


                </div>
            </main>
            <ContactFormPopup show={show} handleClose={handleClose}/>
            </>
            )
            }
            export default About;