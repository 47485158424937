import React from 'react'
import {gsap} from "gsap";
import {Col, Container, Row} from "react-bootstrap";
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Navigation, Virtual} from 'swiper';
import {useMovieData} from "../../../components/data/use-movie-data";
import { Link } from 'react-router-dom';

import MovieItem from "../movie/item";

SwiperCore.use([Navigation, Virtual]);

const gsapAnimate = {
    getData: (elem) => {
        const option = {
            opacity: 0,
            scale: 1,
            position: {
                x: 0,
                y: 0,
            },
            ease: "",
            duration: 1,
            delay: .4,
            rotate: 0
        }
        if (elem !== undefined) {
            option.position.x = gsapAnimate.validValue(elem.dataset.iqPositionX, 0)

            option.position.y = gsapAnimate.validValue(elem.dataset.iqPositionY, 0)

            option.rotate = gsapAnimate.validValue(elem.dataset.iqRotate, 0)

            option.scale = gsapAnimate.validValue(elem.dataset.iqScale, 1)

            option.opacity = gsapAnimate.validValue(elem.dataset.iqOpacity, 0)

            option.delay = gsapAnimate.validValue(elem.dataset.iqDelay, .4)

            option.duration = gsapAnimate.validValue(elem.dataset.iqDuration, 1.5)

            option.ease = gsapAnimate.validValue(elem.dataset.iqEase, '')

            const setOption = {
                opacity: option.opacity,
                scale: option.scale,
                x: option.position.x,
                y: option.position.y,
                ease: option.ease,
                rotate: option.rotate,
                duration: option.duration,
                delay: option.delay
            }

            return setOption
        } else {
            return {opacity: 0}
        }
    },
    onStart: (elem) => {

        const setOption = gsapAnimate.getData(elem)

        gsap.from(elem, setOption)

    },

    onEnd: (elem) => {

        const setOption = gsapAnimate.getData(elem)

        gsap.to(elem, setOption)

    },

    onStartEnd: (elem) => {

        const setOption = gsapAnimate.getData(elem)

        const setEndOption = gsapAnimate.getData(elem)

        setEndOption.opacity = 1

        setEndOption.x = 0

        setEndOption.y = 0

        setEndOption.rotate = 0

        setEndOption.scale = 1

        gsap.fromTo(elem, setOption, setEndOption)
    },
    validValue: (attr, defaultVal) => {
        if (attr !== undefined && attr !== null) {
            return Number(attr)
        }
        return Number(defaultVal)
    }
}


const MovieSlider = ({playlist = "", title = "", isGenre = false, navPrevId, navNextId}) => {
    const {movieData, error, loading} = useMovieData(isGenre ? 'genre' : 'playlist', playlist);

    const animationInit = () => {
        if (document.querySelector('.swiper-container .swiper-slide-active') !== null) {

            const gsapElem = document.querySelector('.swiper-container .swiper-slide-active').querySelectorAll('[data-iq-gsap="onStart"]')

            Array.from(gsapElem, (elem) => {
                return gsapAnimate.onStartEnd(elem)
            })
        }
    }


    const SliderItems = movieData.map((item) => {
        return (
            <SwiperSlide as="li" key={item.element_id}>
                <Link to={`/video/${item.movie_identifier}`}>
                    <MovieItem item={item}/>
                </Link>
            </SwiperSlide>
        )
    })

    return (
        <>
            <section>
                <Container fluid>
                    <Row>
                        <Col sm="12" className="overflow-hidden">
                            <div className="d-flex align-items-center justify-content-between mb-3">
                                <h4 className="main-title font-weight-bold">{title}</h4>
                            </div>
                            <div className="mb-3">
                                <div id={navPrevId} className="swiper-button swiper-button-prev"><i
                                    className="fa fa-chevron-left"></i></div>
                                <div id={navNextId} className="swiper-button swiper-button-next"><i
                                    className="fa fa-chevron-right"></i></div>
                                <Swiper

                                    navigation={{
                                        prevEl: `#${navPrevId}`,
                                        nextEl: `#${navNextId}`
                                    }}
                                    breakpoints={{
                                        320: {slidesPerView: 2},
                                        550: {slidesPerView: 4},
                                        991: {slidesPerView: 5},
                                        1200: {slidesPerView: 6},
                                        1600: {slidesPerView: 10},
                                        2200: {slidesPerView: 11}
                                    }}
                                    loop={true}
                                    slidesPerView={6}
                                    spaceBetween={20}
                                    as="ul"
                                    className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction"
                                >
                                    {SliderItems}
                                </Swiper>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )

}

export default MovieSlider;