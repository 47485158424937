import React, {useState} from 'react'
import {Col, Container, Nav, Navbar, Row} from 'react-bootstrap'
import {Link} from 'react-router-dom'

import ContactFormPopup from '../../../../views/backend/pages/extrapages/contactFormPopup';

//img
import logo from '../../../../assets/images/logo.png';

const HeaderStyle1 = (props) => {
    const [show, setShow] = useState(false);
    const [open, setOpen] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleToggleClick = () => setOpen(!open);

    const handleContactClick = (event) => {
        event.preventDefault(); // Prevent navigation
        handleShow();
        setOpen(false); // Close the navbar
    };
    const handleNavLinkClick = () => setOpen(false);

    return (
        <>
            <header id="main-header">
                <div className="main-header">
                    <Container fluid>
                        <Row>
                            <Col sm="12">
                                <Navbar collapseOnSelect expanded={open} expand="lg" className="p-0">
                                    <Navbar.Toggle className="c-toggler" aria-controls="responsive-navbar-nav" onClick={handleToggleClick}>
                                        <div className="navbar-toggler-icon">
                                            <span className="navbar-menu-icon navbar-menu-icon--top"></span>
                                            <span className="navbar-menu-icon navbar-menu-icon--middle"></span>
                                            <span className="navbar-menu-icon navbar-menu-icon--bottom"></span>
                                        </div>
                                    </Navbar.Toggle>
                                    <Navbar.Brand className="navbar-brand mx-auto pr-5" href="/">
                                        <img className="logo" src={logo} alt="artiflix"/>
                                    </Navbar.Brand>
                                    <Navbar.Collapse id="responsive-navbar-nav">
                                        <Nav as="ul" id="top-menu" className="mr-auto">
                                            <li className="menu-item" onClick={handleNavLinkClick}>
                                                <Link to="/">Home</Link>
                                            </li>
                                            <li className="menu-item" onClick={handleNavLinkClick}>
                                                <Link to="/library">Library</Link>
                                            </li>
                                            <li className="menu-item" onClick={handleNavLinkClick}>
                                                <Link to="/about">About</Link>
                                            </li>
                                            <li className="menu-item" onClick={handleContactClick}>
                                                <Link to="#">Contact</Link>
                                            </li>
                                        </Nav>
                                        <Nav className="ml-auto search-nav">
                                            <Nav.Link href="/library"><i className="fa fa-search"></i></Nav.Link>
                                        </Nav>
                                    </Navbar.Collapse>
                                </Navbar>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </header>
            <ContactFormPopup show={show} handleClose={handleClose} />
        </>
    )
}

export default HeaderStyle1
