import React from 'react';
import { Helmet } from 'react-helmet';


const NotFound = () => {
    return (
        <div className="error404-container">
            <Helmet>
                <meta name="robots" content="noindex" />
                <title>Page Not Found</title>
            </Helmet>
            <h1 className='error404-header'>404 Error</h1>
            <div className='error404-text'>Oops! The page you're looking for doesn't exist.</div>
        </div>
    );
};

export default NotFound;
