import React, {useEffect, useState} from 'react'
import {Button, Col, Container, Form, Row} from 'react-bootstrap'
import {useLocation, useNavigate} from 'react-router-dom'
import queryString from 'query-string'
import SwiperCore, {Navigation} from 'swiper';
import 'swiper/swiper-bundle.css';
import {ChangeMetaBasic} from '../seo/change-meta'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//img
import SearchResult from "./result";

// install Swiper modules
SwiperCore.use([Navigation]);

const MovieLibrary = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [releaseDateFrom, setReleaseDateFrom] = useState(new Date(1900, 0, 1));
    const [releaseDateTo, setReleaseDateTo] = useState(new Date());
    const [keywords, setKeywords] = useState('');
    const [sortBy, setSortBy] = useState('');
    const [selectedGenres, setSelectedGenres] = useState([]);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const searchValue = searchParams.get('search');
        setKeywords(searchValue ? searchValue : '');

        setCurrentSeoData({
            title: 'The Artiflix Library',
            description: 'Rediscover timeless classics digitally revived on Artiflix.  Artiflix Library of classic movies.',
            url: 'https://artiflix.com/library',
            shareImage: 'https://artiflix.com/logo192.png',
            keywords: 'Artiflix, Forgotten Cinematic Treasures, Classic Movies Streaming, Free Movies, Artiflix Library, Library'
        });
    }, []);

    useEffect(() => {
        const currentSearch = queryString.parse(location.search).search || '';
        if (currentSearch !== keywords) {
            const newQueryString = queryString.stringify({search: keywords});
            navigate(location.pathname + '?' + newQueryString, {replace: false});
        }
    }, [keywords, navigate, location.pathname, location.search]);

    const [movieIdentifier, setMovieIdentifier] = useState('');
    const handleShow = (movieIdentifier) => {
        setMovieIdentifier(movieIdentifier);
    }

    const DEFAULT_SEO_DATA = {
        title: 'The Artiflix Library',
        description: 'Rediscover timeless classics digitally revived on Artiflix.  Artiflix Library of classic movies.',
        url: 'https://artiflix.com/library',
        shareImage: 'https://artiflix.com/logo192.png',
        keywords: 'Artiflix, Forgotten Cinematic Treasures, Classic Movies Streaming, Free Movies, Artiflix Library, Library'
    };

    const [currentSeoData, setCurrentSeoData] = useState(DEFAULT_SEO_DATA);


    // genresList to be replaced with CMS data from API
    const genresList = [
        'Action',
        'Adventure',
        'Animated',
        'Biography',
        'Children',
        'Comedy',
        'Crime',
        'Drama',
        'Documentary',
        'Fantasy',
        'History',
        'Horror',
        'Musical',
        'Mystery',
        'Romance',
        'Science Fiction',
        'Thriller',
        'War',
        'Western']


    return (
        <>
            <ChangeMetaBasic seoData={currentSeoData}/>
            <div className="library-content">
                <Container fluid>
                    <Row>
                        <Col lg="3" sm="12">
                            <div className="library-title">
                                <h1>Artiflix Library</h1>
                            </div>

                            <div id="search">
                                <div className="field-set-container">
                                    <div className="field-set-title">Keyword Search</div>
                                    <Form.Group>
                                        <Form.Control className="input-keywords" type="text" name="keywords"
                                                      placeholder="Filter by titles, credits, genre, description"
                                                      value={keywords}
                                                      onChange={e => setKeywords(e.target.value)}/>
                                    </Form.Group>
                                </div>

                                <div className="field-set-container">
                                    <div className="field-set-title">Sort</div>
                                    <Form.Group>
                                        <Form.Label>Sort Results By</Form.Label>
                                        <Form.Control name="sort_by" as="select" value={sortBy}
                                                      onChange={e => setSortBy(e.target.value)}>
                                            <option value="">None</option>
                                            <option value="title_a_to_z">Title (A - Z)</option>
                                            <option value="title_z_to_a">Title (Z - A)</option>
                                            <option value="release_date_ascending">Release Date Ascending</option>
                                            <option value="release_date_descending">Release Date Descending</option>
                                        </Form.Control>
                                    </Form.Group>
                                </div>

                                <div className="field-set-container">
                                    <div className="field-set-title">Filters</div>
                                    <Form.Group>
                                        <Form.Label>Genres</Form.Label>
                                        <Form.Control name="filter_by_genres[]"
                                                      as="select"
                                                      multiple
                                                      value={selectedGenres}
                                                      onChange={e => setSelectedGenres(Array.from(e.target.selectedOptions, option => option.value))}>
                                            {genresList.map((genre, index) => (
                                                <option key={index} value={genre}>{genre}</option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Release Dates</Form.Label>
                                        <Form.Text className="text-muted">
                                            From
                                        </Form.Text>
                                        <DatePicker className="form-control" name="filter_by_release_dates_from"
                                                    selected={releaseDateFrom}
                                                    onChange={(date) => setReleaseDateFrom(date)}/>
                                        <Form.Text className="text-muted">
                                            To
                                        </Form.Text>
                                        <DatePicker className="form-control" name="filter_by_release_dates_to"
                                                    selected={releaseDateTo}
                                                    onChange={(date) => setReleaseDateTo(date)}/>
                                    </Form.Group>
                                </div>
                                <Button className="search-btn" variant="primary" type="submit">
                                    Clear
                                </Button>
                            </div>
                        </Col>
                        <Col className="mb-5" lg="9" sm="12">
                            <SearchResult keywords={keywords} genre={selectedGenres} sortBy={sortBy}
                                          fromDate={releaseDateFrom}
                                          toDate={releaseDateTo} />
                        </Col>
                    </Row>
                </Container>
            </div>


        </>
    )
}

export default MovieLibrary;